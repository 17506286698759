<template>
  <div class="content">
    <div class="title">
      <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal">
          <el-menu-item  @click="switchCollapse"  style="width: 44px">
            <img src="@/assets/logo.png" alt="" style="width: 25px; height: 25px;">
          </el-menu-item>
          <el-menu-item>
            <span style="font-weight: 600; color: #409EFF;font-size: 22px">应用中心</span>
          </el-menu-item>
          <el-menu-item v-if="userData.isAdmin">
            <div class="menu-line"></div>
            <el-button @click="switchMode" type="text">前往设计</el-button>
          </el-menu-item>
          <el-menu-item v-if="sysInfoModel.name">
            <div class="menu-line"></div>
            <span style="color: #409EFF;font-size: 14px">{{sysInfoModel.name}}</span>
          </el-menu-item>
          <el-submenu class="user" index="2">
            <template slot="title">{{userData.name}}</template>
            <el-menu-item @click="showUserInfo" index="2-1">我的资料</el-menu-item>
            <el-menu-item @click="logout" index="2-3">退出登录</el-menu-item>
          </el-submenu>
      </el-menu>
    </div>
    <div class="body">
      <!-- 应用菜单 -->
      <div class="menu">
        <div class="menu-search">
          <el-input placeholder="搜索菜单" v-model="searchMenuVal" @clear="clearFilterMenu"  clearable class="input-with-select">
            <el-button @click="filterMenu()" slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </div>
        <el-skeleton class="skeleton" v-if="loading" :rows="16" animated />
        <el-menu
          v-if="!loading"
          :default-active="menuActiveIndex"
          :collapse="isCollapse"
          class="el-menu-vertical-demo">
          <div v-for="(menu, index) in menuList" :key="index">
            <!-- 有流程的 -->
            <el-submenu :index="menu.id" v-if="menu.mode == 0 && menu.type == 1">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span v-if="!isCollapse">{{menu.name}}</span>
              </template>
              <el-menu-item-group v-for="(smenu, sindex) in menu.items" :key="sindex" >
                <template slot="title">{{ smenu.name }}</template>
                <div class="menu-null" v-if="!smenu.items || smenu.items.length < 1">该流程暂无表单</div>
                <el-menu-item @click="jumpApp(ssmenu)" v-for="(ssmenu, ssindex) in smenu.items" :key="ssindex" 
                  :index="ssmenu.id">{{ ssmenu.name }}</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <!-- 无流程的 -->
            <el-submenu :index="menu.id" v-if="menu.mode == 1 && menu.type == 1">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span v-if="!isCollapse">{{menu.name}}</span>
              </template>
              <div class="menu-null" v-if="!menu.items || menu.items.length < 1">该应用暂无表单</div>
              <el-menu-item @click="jumpApp(ssmenu)" v-for="(ssmenu, ssindex) in menu.items" :key="ssindex" 
                  :index="`${index}` + '-' + `${ssindex}`">
                  <span v-if="ssmenu.type != 20">{{ ssmenu.name }}</span>
                  <span v-if="ssmenu.type == 20 && ssmenu.dumpMode != 1" style="color: #409EFF;">{{ ssmenu.name }}</span>
                  <span v-if="ssmenu.type == 20 && ssmenu.dumpMode == 1" style="color: #409EFF;">> {{ ssmenu.name }}</span>
                </el-menu-item>
            </el-submenu> 
            <!-- 报表 -->
            <el-menu-item v-if="menu.type == 10" @click="jumpApp(menu)" :index="menu.id">
                <template slot="title">
                  <i class="el-icon-tickets"></i>
                  {{ menu.name }}
                </template>
            </el-menu-item>
            <el-submenu :index="menu.id" v-if="menu.type == 11">
              <template slot="title">
                <i class="el-icon-s-data"></i>
                <span v-if="!isCollapse">{{menu.name}}</span>
              </template>
              <div class="menu-null" v-if="!menu.items || menu.items.length < 1">该类别暂无报表</div>
              <div v-if="menu.type == 10">
                <el-menu-item @click="jumpApp(menu)" :index="menu.id">
                    <template slot="title">
                      <i class="el-icon-tickets"></i>
                      {{ menu.name }}
                    </template>
                </el-menu-item>
              </div> 
              <div v-if="menu.type == 11 && menu.items && menu.items.length > 0">
                <div v-for="(ssmenu, ssindex) in menu.items" :key="ssindex">
                  <div v-if="ssmenu.type == 10">
                      <el-menu-item @click="jumpApp(ssmenu)" :index="ssmenu.id">
                          <template slot="title">
                            <i class="el-icon-tickets"></i>
                            {{ ssmenu.name }}
                          </template>
                      </el-menu-item>
                  </div> 
                  <div v-if="ssmenu.type == 11">
                    <el-submenu :key="ssindex" :index="ssmenu.id">
                        <template slot="title"> <i class="el-icon-s-data"></i>{{ ssmenu.name }}</template>
                        <div v-if="ssmenu.type == 11" >
                          <el-menu-item
                            @click="jumpApp(sssmenu)"
                            v-for="(sssmenu, sssindex) in ssmenu.items"  :key="sssindex" 
                            :index="`${index}` + '-' + `${ssindex}`+ `${sssindex}`">
                            <i class="el-icon-tickets"></i>{{ sssmenu.name }}
                          </el-menu-item>
                          <div class="menu-null" v-if="!ssmenu.items || ssmenu.items.length < 1">该类别暂无报表</div>
                        </div>
                    </el-submenu>
                  </div>
                </div>
                <div class="menu-null" v-if="!menu.items || menu.items.length < 1">该类别暂无报表</div>
              </div>
            </el-submenu>
          </div>
          <el-empty :image-size="25" class="empty" v-if="loading == false && menuList.length < 1" description="暂无菜单"></el-empty>
        </el-menu>
      </div>
      <div class="main">
        <router-view v-if="!currExtData" /> 
        <el-skeleton class="skeleton" v-if="extloading" :rows="16" animated />
        <iframe v-if="currExtData" class="ext" :src="currExtData.path" frameborder="0"></iframe>
      </div>
    </div>
    <el-dialog  
        title="用户资料"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        width="600px"
        :visible.sync="userInfoVisible">
        <div>
            <user-info />
        </div>
    </el-dialog>
  </div>
</template>

<script>
import { getSysInfo } from '@/services/app.info.service';
import UserInfo from '@/components/users/UserInfo.vue';
import { clearCache, getLocalUser } from '@/services/user.service';
import { getMenu } from '@/services/app.service';
import { getExtData } from '@/services/app.manager.serivce';  
export default {
  name: 'AppView',
  components: {
    UserInfo
  },
  created(){
      this.userData = getLocalUser();
  },
  data() {
    return {
      sysInfoModel: {name: ''},
      userInfoVisible: false,
      activeIndex: '',
      menuActiveIndex: '',
      isCollapse: false,
      extloading: false,
      loading: false,
      menuList: [],
      allMenuList: [],
      currExtData: undefined,
      searchMenuVal: ''
    };
  },
  mounted(){
      this.getAppMenu();
      getSysInfo(true).then(res=>{
          console.log('getSysInfo' + res)
          this.sysInfoModel.name = res ? res.name : ''; 
      });
  },
  methods: {
    getAppMenu(){
      console.log('getAppMenu');
      this.loading = true;
      getMenu().then(res=>{
          this.allMenuList = JSON.stringify(res);
          this.menuList = res;
          if (this.$route.params.id) { 
              let index = this.menuList.findIndex(c=> c.id == this.$route.params.id);
              if (index > -1) {
                this.menuActiveIndex = this.menuList[index].id;
                if (this.menuList[index].items && this.menuList[index].items.length > 0) {
                  this.jumpApp(this.menuList[index].items[0]);
                }
              }
          }
          this.loading = false;
      },()=>{
        this.loading = false;
      })
    },
    clearFilterMenu(){
      this.menuList = JSON.parse(this.allMenuList);
    },
    filterMenu(){
      this.searchMenuVal = this.searchMenuVal.trim();
      if (!this.searchMenuVal){
        this.menuList = JSON.parse(this.allMenuList);
        return;
      }
      let rmList = [];
      this.menuList.forEach((c, index)=>{
        let rm = true;
        if (c.items && c.items.length > 0) {
          c.items.forEach((cc, indexx)=>{
            if (cc.name.indexOf(this.searchMenuVal) == -1) {
              rmList.push(index + ","+ indexx);
            } else {
              rm = false;
            }
          })
        }
        if(rm){
          if (c.name.indexOf(this.searchMenuVal) == -1) {
            rmList.push(index);
          } 
        } 
      })
      // 逆序排序，确保先删除子项，再删除父项
      rmList.sort((a, b) => {
          let aVal = a.toString().split(',');
          let bVal = b.toString().split(',');
          return bVal[0] - aVal[0] || bVal[1] - aVal[1];
      });

      if (rmList.length > 0) {
          rmList.forEach(c => {
              let val = c.toString().split(',');
              if (val.length === 1) {
                  console.log('rm' + c)
                  this.menuList.splice(c, 1);
              } else {
                  console.log('rm' + val[0] + ' items ' + val[1])
                  this.menuList[val[0]].items.splice(val[1], 1);
              }
          });
      }
      console.dir(this.menuList);
    },
    showUserInfo(){
      this.userInfoVisible = true;
    },
    loadExt(id) {
      this.extloading = true;
      getExtData(id).then(res=>{       
          this.extloading = false;
          this.currExtData = res;
          if (res.dumpMode && res.dumpMode == 1){
            window.open(res.path, '_blank');
          } 
      });
    },
    jumpApp(data) {
      this.currExtData = undefined;
      if (data.type == 10) {
        this.$router.push('/index/report/' + data.id);
      } else if (data.type == 20) {
        this.loadExt(data.id);
      } else {
        this.$router.push('/index/app/' + data.id);
      }
    },
    switchMode(){
      this.$router.push('/home');
    }, 
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    switchCollapse(){
      this.isCollapse = !this.isCollapse;
    },
    logout(){
      clearCache();
      this.$router.push('/login');
    },
    jumpRouter(router){
      this.$router.push(router);
    }
  }
}
</script>

<style lang="scss" scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 240px;
}
.el-menu-vertical-demo {
  height: 100%;
}
.content {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.title {
  width: 100%;
  height: 60px;
  padding: 0;
  margin-bottom: 1px;
}
.menu-line {
  border-left: 1px solid #ccc; 
  height: 40%; 
  width: 2px; 
  position: absolute; 
  left: 0; 
  top: 20px;
}
.switchmode {
  position: absolute;
  right: 5px;
}
.user {
  position: absolute;
  right: 5px;
}
.body {
  max-height: calc(100vh - 45px);
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow-y: hidden;

  .menu {
    height: 100%;
    transition: all 0.8s;
    background: #fff;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    padding-top: 44px;

    .skeleton {
      width: 224px;
      height: calc(100% - 20px);
      margin: 10px 8px 10px 8px;
    }
  }
  .menu-search {
    height: 40px; 
    position: fixed;
    top: 62px;
    left: 0;
    z-index: 9999;
    width: 240px;
  }

  .menu-null {
    height: 40px;
    line-height: 40px;
    font-size: 12px;
    color: #a6a0a0;
    margin-left: 39px;
  }

  .main {
    height: calc(100vh - 61px);
    overflow-y: auto;
  }
  .main::-webkit-scrollbar {
      height: 12px; /* 滚动条高度 */
      background-color: #F2F6FC; /* 滚动条背景颜色 */
  }
  /* 滚动条滑块样式 */
  .main::-webkit-scrollbar-thumb {
      background-color: #909399; /* 滚动条滑块颜色 */
      border-radius: 6px; /* 滑块圆角 */
  }
}

.ext {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: auto;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #F2F6FC;
  border-radius: 2px;
}
::-webkit-scrollbar:hover {
  width: 10px;
  height: 10px;
  background-color: #DCDFE6;
  border-radius: 2px;
}
</style>
